import React from 'react';
import {ProductUnit} from "../../../../__generated__/types";
import RoundImage from "../../../../shared/components/RoundImage";
import {amountWithUnit} from "../../../../shared/utils/unit.utils";
import classNames from "classnames";
import {productIsAvailableAsAlternativeUnitFor} from "../../../../shared/utils/product.utils";
import {formatDoubleDigit} from "../../../../shared/utils/currency.utils";
import {Button} from "primereact/button";

interface ProductPackagingViewProps {
  product: {
    image?: string | null | undefined;
    name?: string | null | undefined;
    unit: ProductUnit | null;
    availableAsAlternativeUnitFor: string[] | null;
    avgWeight: number | null;
    alternativeUnit: ProductUnit | null;
  },
  quantity: number;
  portions?: number;
  isDelivered: boolean;
  isUndeliverable: boolean;
  onDelivered: (isDelivered: boolean) => void;
  onToggleUndeliverable?: (isUndeliverable: boolean) => void;
  clientGroupId: string;
}

const ProductPackagingView = (props: ProductPackagingViewProps) => {
  const {product} = props;

  let unit = product.unit;
  let quantity = props.quantity;
  if(product.avgWeight && productIsAvailableAsAlternativeUnitFor(product, props.clientGroupId)) {
    unit = product.alternativeUnit
    quantity = quantity / product.avgWeight;
  }

  return <div
    onClick={() => {
      props.onDelivered(!props.isDelivered);
    }}
    className={classNames(
      "m-2 cursor-pointer border-1 border-400 p-2 flex justify-content-start align-items-center text-2xl",
      {"border-2 border-green-500 bg-green-200": props.isDelivered},
      {"border-2 border-red-500 bg-red-200": props.isUndeliverable},
    )}>
    <div className="min-w-4rem mr-1">
      <RoundImage size={"md"} src={product.image}/>
    </div>
    <div className="white-space-nowrap font-bold text-800 min-w-8rem w-8rem text-right pr-2">
      {amountWithUnit(quantity, unit)}
      {props.portions && <div className="text-xs text-500">{formatDoubleDigit(props.portions)} punten</div>}
    </div>
    <div className="px-2 font-medium flex-grow-1">{product.name}</div>
    <div className="">
      <Button
        className='text-4xl'
        icon={'pi pi-times-circle'}
        rounded
        text
        severity={"danger"}
        iconPos={'right'}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          return props.onToggleUndeliverable && props.onToggleUndeliverable(!props.isUndeliverable);
        }
        }
      />
    </div>
  </div>;
};

export default ProductPackagingView;
