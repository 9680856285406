import gql from "graphql-tag";
import {BasketCompositionFieldsFragment} from "./basketComposition.gql";
import {ProductFields_basicFragment} from "./product.gql";

export const ClientOrderFieldsFragment = gql`
${ProductFields_basicFragment}
${BasketCompositionFieldsFragment}
fragment ClientOrderFields on ClientOrder {
  id
  createdAt
  status
  completionStatus
  orderDiscount
  orderDate
  delivery
  deliveryDate
  deliveryEndDate
  orderDiscount
  productOrders {
    id
    quantity
    remark
    priceExcl
    ambassadorDiscount
    frequency
    portions
    usedPortions
    productDescription
    unit
    vat
    deliveries(orderBy: plannedDeliveryDate_ASC) {
      id
      plannedDeliveryDate
      deliveryDate
      cancelled
      deliveryLocation
      deliveredProductIds
      undeliverableProductIds
      basketComposition {
        ...BasketCompositionFields
      }
    }
    product {
      ...ProductFields_basic
      name
      vat
      amount
      type
      avgWeight
      availableAsAlternativeUnitFor
      alternativeUnit
      unit
      image
      productPrices(where: {
        clientGroup: {
          code: "standaard"
        }
        endDate: null
      }) {
        value
        startDate
        endDate
      }
    }
  }
  client {
    id
    type
    name
    firstName
    companyName
    street
    streetNumber
    cityPostalCode
    city
    vatNumber
    type
    email
    billingEmail
    group {id}
  }
  invoice {
    id
    type
    number
    filename
    data
    createdAt
    payments {
      id
      paymentDate
      dueDate
      amount
      paid
    }
  }
}
`;

export const ClientOrderQuery = gql`
${ClientOrderFieldsFragment}
query ClientOrder($id: ID!) {
  clientOrder(
    where: {
      id: $id
    }
  ) {
    ...ClientOrderFields
  }
}
`;

export const ClientOrdersQuery = gql`
${ClientOrderFieldsFragment}
query ClientOrders($where: ClientOrderWhereInput!) {
  clientOrders(
    where: $where
  ) {
    ...ClientOrderFields
  }
}`;

export const ClientOrderDeleteMutation = gql`
mutation DeleteClientOrder($id: ID!) {
 deleteManyProductOrderDeliveries(where: {productOrder:{clientOrder: {id: $id}}}) {
    count
  }
  deleteManyProductOrders(where: {clientOrder: {id: $id}}) {
    count
  }
  deleteClientOrder(where: {id: $id}) {
    id
  }
}
`;

export const ClientOrderUpdateMutation = gql`
${ClientOrderFieldsFragment}
mutation ClientOrderUpdate($where: ClientOrderWhereUniqueInput!, $data: ClientOrderUpdateInput!) {
  updateClientOrder(
    where: $where
    data: $data
  ) {
    ...ClientOrderFields
  }
}
`;

export const CreateClientOrderInvoiceMutation = gql`
mutation CreateClientOrderInvoice(
  $invoiceData: InvoiceCreateInput!,
  $clientOrders: [ID!]
  $sequenceId: ID!,
  $nextSequenceValue: Int!
) {
  updateSequence(where: {
    id: $sequenceId
  }
  data: {
    nextValue: $nextSequenceValue
  }) {
    id
    sequenceType
    prefix
    nextValue
    season
  }
	updateManyClientOrders(
    where: {
      id_in: $clientOrders
    }
    data: {
      status: INVOICED,
    }
  ) {
    count
  }
 createInvoice(
  data: $invoiceData
) {
  	clientOrders {
      id
      createdAt
      updatedAt
      orderDate
      orderDiscount
      deliveryDate
      delivery
      status
      client {
        id
        type
      }
      productOrders {
        id
        product {
          id
          vat
        }
        priceExcl
        quantity
        remark
        ambassadorDiscount
      }
      invoice {
        id
        createdAt
        type
        number
        filename
        data
        payments {
          id
          paymentDate
          dueDate
          amount
          paid
        }
      }
    }
	}
}
`;

export const UpdateClientOrderInvoiceMutation = gql`
mutation UpdateClientOrderInvoice(
  $invoiceData: InvoiceCreateInput!,
  $oldInvoiceId: ID!
) {
  deleteManyPayments(where: {invoice: {id: $oldInvoiceId}}) { count }

  deleteInvoice(where: {id: $oldInvoiceId}) { id }

  createInvoice(
  data: $invoiceData
  ) {
    clientOrders {
      id
      createdAt
      updatedAt
      orderDate
      deliveryDate
      delivery
      status
      orderDiscount
      client {
        id
        type
      }
      productOrders {
        id
        product {
          id
          vat
        }
        priceExcl
        quantity
        remark
        ambassadorDiscount
      }
      invoice {
        id
        createdAt
        type
        number
        filename
        data
        payments {
          id
          paymentDate
          dueDate
          amount
          paid
        }
      }
    }
  }
}
`;

export const ClientOrderUpdatePaymentStatusMutation = gql`
mutation ClientOrderUpdatePaymentStatus(
  $clientOrderId: ID!,
  $paymentId: ID!,
  $date: DateTime!,
  $paid: Boolean!,
  $clientOrderStatus: ClientOrderStatus!,
) {
    updateClientOrder(
      where: {id: $clientOrderId}
      data: {status: $clientOrderStatus}
    ) {
      id
      status
    }
  updatePayment(
    where: {
      id: $paymentId
    }
    data: {
    	paymentDate: $date
    	paid: $paid
  	}
  ) {
    id
    paymentDate
    dueDate
    amount
    paid
  }
}`;

