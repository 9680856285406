import React from 'react';
import {useQuery} from "@apollo/client";
import {BasketCompositions_basketCompositions, ClientOrder, DeliveryLocation} from "../../../../__generated__/types";
import {ClientOrderQuery} from "../../../../shared/queries/clientOrder.gql";
import {filterDeliveredDeliveries, filterPlannedDeliveries} from "../../../../utils/productOrder.utils";
import {compact, first, mean} from "lodash";
import ProgressBar from 'shared/components/ProgressBar/ProgressBar';
import {formatToMaxPrecision} from "../../../../shared/utils/currency.utils";
import {roundHalf} from "../../../../shared/utils/number.utils";
import {momentFromIso8601} from "../../../../shared/utils/date.utils";
import {avgPointsSuggestion, totalPortions} from "../../../../shared/utils/basketComposition.utils";
import {BasketSize, getBasketSize} from "../../../../shared/utils/product.utils";
import {findFlexBasketProductOrder} from "../../../../shared/utils/productOrder.utils";
import {deliveries_deliveredPortions, delivery_totalPortions} from "../../../../utils/clientOrder.utils";
import {useBasketCompositions_comparableForPreviousYear_Query} from "../../../../mutations/basketComposition.queries";
import {nextDelivery} from "../../../../shared/utils/clientOrder.utils";
import {useUserObject} from "../../../../shared/context/UserContext";

interface FlexSubscriptionStatusOverviewProps {
  clientOrderId: string;
  deliveryDate?: string;
}

const FlexSubscriptionStatusOverview = (props: FlexSubscriptionStatusOverviewProps) => {
  const {activeFarm} = useUserObject();
  const {data} = useQuery<ClientOrder>(ClientOrderQuery, {variables: {id: props.clientOrderId}});

  let deliveryWeek = props.deliveryDate ? momentFromIso8601(props.deliveryDate) : null;
  const {data: basketCompositionData} = useBasketCompositions_comparableForPreviousYear_Query(activeFarm?.id||'', deliveryWeek);

  let flexBasketProductOrder = findFlexBasketProductOrder(data?.clientOrder?.productOrders);

  if (!flexBasketProductOrder) {
    return null;
  }

  let deliveries = compact(flexBasketProductOrder.deliveries);
  const deliveredDeliveries = filterDeliveredDeliveries(deliveries);
  const plannedDeliveries = filterPlannedDeliveries(deliveries);

  let usedPortions = deliveries_deliveredPortions(flexBasketProductOrder?.product, deliveredDeliveries);
  let plannedDelivery = nextDelivery(deliveries);
  let usedPortionsIncl = usedPortions + (plannedDelivery ? delivery_totalPortions(flexBasketProductOrder?.product, plannedDelivery) : 0);

  let totalRemainingPortions = (flexBasketProductOrder.portions || 0) - usedPortions;
  let totalRemainingPortionsIncl = (flexBasketProductOrder.portions || 0) - usedPortionsIncl;

  const portionsForComparableBasketCompositions = avgPointsSuggestion(
    compact(basketCompositionData?.basketCompositions),
    getBasketSize(flexBasketProductOrder?.product),
    first(deliveries)?.deliveryLocation
  );

  let remainingPointsPerDelivery = roundHalf(totalRemainingPortions / plannedDeliveries.length, 0.25);
  return <div className="border-bottom-1 border-300 mb-3 pb-3">
    <div className="text-xl font-medium">Pakketoverzicht</div>
    <div className="text-sm mb-3">{flexBasketProductOrder.product?.name}</div>

    <ProgressBar label="Pakketten" max={deliveries.length} value={deliveredDeliveries.length} suffix="ontvangen"/>

    <ProgressBar label="Punten" max={flexBasketProductOrder.portions} value={usedPortions}
                 labelValue={totalRemainingPortions}
                 suffix="resterend"/>

    {plannedDelivery?.basketComposition &&
      <div>Resterende punten na dit pakket: <span className="font-bold">{totalRemainingPortionsIncl} punten</span>
      </div>}

    <div className="mt-3 mb-1">Jouw pakketgemiddeldes:</div>
    <div className="flex flex-row">
      <div className="flex flex-1 align-items-center flex-column mr-1 p-1 border-1 border-300 border-round">
        <div>{formatToMaxPrecision(roundHalf(usedPortions / deliveredDeliveries.length, 0.25), 2)} punten</div>
        <div>verbruikt</div>
        <div className="text-xs text-600">per pakket</div>
      </div>

      <div className="flex flex-1 align-items-center flex-column p-1 border-1 border-300 border-round">
        <div>{formatToMaxPrecision(remainingPointsPerDelivery, 2)} punten</div>
        <div>resterend</div>
        <div className="text-xs text-600">per pakket</div>
      </div>
    </div>
    {!isNaN(portionsForComparableBasketCompositions) && <div>
      <div className="mt-3 mb-1">Onze suggestie voor dit pakket:</div>
      <div className="flex flex-row">
        <div className="flex flex-1 align-items-center flex-column mr-1 p-1 border-1 border-300 border-round">
          <div>{formatToMaxPrecision(roundHalf(portionsForComparableBasketCompositions, 0.25), 2)} punten</div>
          <div></div>
          <div className="text-xs text-600">per pakket in deze periode</div>
        </div>
      </div>
    </div>}
  </div>;
};

export default FlexSubscriptionStatusOverview;
