import { gql } from "@apollo/client";

export const ClientsBillingQuery = gql`
query ClientsBillingData($id: ID!) {
  client: client(where: {id: $id}) {
    name
    farm { code name }
    billingEmail
    clientOrders(orderBy: createdAt_DESC) {
      id
      status
      invoice {
        id
        createdAt
        type
        number
        filename
        data
        payments {
          id
          paymentDate
          dueDate
          amount
          paid
        }
      }
  	}
  }
}
`;
