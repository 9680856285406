import React, {useEffect, useState} from 'react';
import {StringParam, useQueryParam} from "use-query-params";
import styled from "styled-components";
import {chain, compact, first, round, sum} from 'lodash';
import {ClientOrderStatus, ClientsBillingData, SalesData_clientOrders_invoice} from "../../__generated__/types";
import {ClientsBillingQuery} from "./ClientsBillingPage.gql";
import {formatCurrency} from "../../shared/utils/currency.utils";
import {useQuery} from "@apollo/client";
import {asDdMmYyyy, momentFromIso8601} from "../../shared/utils/date.utils";
import classNames from "classnames";
import {paymentIsOverdue} from "../SubscriptionsPage/components/Dashboard/DashboardItemRevenue";
import ClientOrderInvoicePdf from "../../shared/pdf/ClientOrderInvoicePdf";
import {PDFViewer} from "@react-pdf/renderer";
import {Checkbox} from "primereact/checkbox";

const Payments = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2vh;

`;

const Payment = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 2vw;

  padding: 0.75rem;

  background-color: var(--yellow-100);
  border: 1px solid var(--surface-300);

  .paid {
    background-color: var(--green-100);
    border: 1px solid var(--green-300);
    color: var(--sruface-500);
  }

  .overdue {
    background-color: var(--red-100);
    border: 1px solid var(--red-300);
    font-weight: bold;
  }
`;

interface Invoice extends SalesData_clientOrders_invoice {
  status: 'paid' | 'open';
  totalPaid: number;
}

const ClientsBillingPage = () => {
    const [clientId] = useQueryParam('client', StringParam);
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [selectedInvoice, setSelectedInvoice] = useState<Invoice>();
    const [showPaid, setShowPaid] = useState<boolean>(false);
    const {data} = useQuery<ClientsBillingData>(ClientsBillingQuery, {variables: {id: clientId}});

    useEffect(() => {
      if (data && data.client) {
        const value = chain(data.client.clientOrders)
          .map(clientOrder => clientOrder.invoice)
          .compact()
          .uniqBy('id')
          .map((invoice): Invoice => {
            let payments = compact(invoice.payments);
            const totalPaid = sum(payments.filter(p => p.paid).map(p => p.amount));
            let paid = false;
            if ((round(invoice.data.totalIncl, 2) - round(totalPaid, 2) === 0)
              || (compact(data?.client?.clientOrders)
                .findIndex(clientOrder => clientOrder.invoice?.id === invoice.id && clientOrder.status === ClientOrderStatus.PAID) > -1)) {
              paid = true;
            }

            return {
              ...invoice,
              status: paid ? 'paid' : 'open',
              totalPaid,
            }
          })
          .value();

        setInvoices(value);
        setSelectedInvoice(value.find(x =>x.status === "open"))
      }

    }, [clientId, data]);

    let paymentsView = (invoice: Invoice) => {
      if (invoice.status === 'paid') {
        return <span
          className={'inline-block text-green-700 font-bold bg-green-100 p-2 m-1 border-round-md'}>Betaald</span>;
      } else if (compact(invoice.payments).length > 1) {
        return <div>
          <div>Nog te betalen: <strong>{formatCurrency(invoice.data.totalIncl - invoice.totalPaid)}</strong></div>
          <Payments>
            {compact(invoice.payments).map((payment, idx) => (
              <Payment className={classNames({paid: payment.paid} + ' m-2')}>
                <div> Rekeningnummer:</div>
                <div><strong>BE46 5230 8118 5136</strong><br/></div>

                <div>Mededeling:</div>
                <div>
                  <strong>{invoice.filename.replace('.pdf', '')}{idx > 0 ? `-${idx + 1}` : ''}</strong><br/>
                </div>
                <div>Bedrag:</div>
                <div><strong>{invoice && formatCurrency(payment.amount)}</strong><br/></div>
                <div>Vervaldag:</div>
                <div><strong>{invoice && asDdMmYyyy(momentFromIso8601(payment.dueDate))}</strong><br/></div>
                <div>Voldaan:</div>
                <div><strong>{invoice && payment.paid ? 'Ja' : 'Nee'}</strong><br/></div>
              </Payment>
            ))}
          </Payments>
        </div>;
      }
    };
  let farm = data?.client?.farm;
  if(!farm) {
    return null;
  }

  return <div className="my-0 mx-auto" style={{maxWidth: "900px"}}>
      <div className="m-2">
        <div className="text-xl lg:text-2xl font-bold mb-3">Facturatieoverzicht</div>
        <div className="text-l lg:text-xl font-medium mb-2">Te betalen facturen</div>

        <div>
          {(showPaid ? invoices : invoices.filter(invoice => invoice.status !== 'paid'))
            .map(invoice => {
              let payments = compact(invoice.payments);
              let firstPayment = first(payments);
              let isOverdue = payments.length === 1 && firstPayment && paymentIsOverdue(firstPayment);
              return <div
                onClick={() => {
                  setSelectedInvoice(invoice);
                }}
                className={
                  classNames(
                    "border-1 border-100 my-1 bg-gray-50 cursor-pointer",
                    {
                      "mx-3": selectedInvoice?.id !== invoice.id,
                      "border-2 border-800 mx-1 shadow-1 my-2": selectedInvoice?.id === invoice.id,
                      "border-red-200": isOverdue
                    })}>
                <div className="p-1 flex justify-content-between align-items-center">
                  <div>
                    <div className="text-color-secondary text-2xs lg:text-xs uppercase">nummer</div>
                    <div className="text-xs lg:text-base">{invoice.number}</div>
                    <div className="pt-2 text-color-secondary text-2xs lg:text-xs uppercase">datum</div>
                    <div className="text-xs lg:text-base">{asDdMmYyyy(momentFromIso8601(invoice.createdAt))}</div>
                  </div>
                  <div className="flex flex-column justify-content-end text-right">
                    <div className="text-color-secondary text-2xs lg:text-xs uppercase">bedrag</div>
                    <div className={classNames({
                      "text-red-500": isOverdue
                    })}>
                      {formatCurrency(invoice.data.totalIncl)}
                    </div>
                    <div>
                      {
                        payments.length === 1
                          ? <div className="text-xs lg:text-base text-right pt-2">
                            <div className="pt-1 text-color-secondary text-2xs lg:text-xs uppercase">vervaldatum</div>
                            <div className={classNames({
                              "text-red-300": isOverdue
                            })}>{asDdMmYyyy(momentFromIso8601(first(payments)?.dueDate))}</div>
                          </div>
                          : <div></div>
                      }
                    </div>
                  </div>
                </div>
                {isOverdue &&
                  <div className="flex align-items-center bg-red-200 p-1 text-xs lg:text-base text-white"><i
                    className="pi pi-exclamation-triangle pr-2"/> Betaal spoeding, de vervaldatum is reeds overschreden.
                  </div>}
                <div>
                  {paymentsView(invoice)}
                </div>
              </div>;
            })}
        </div>

        <div className="my-1 text-xs flex align-items-center">
          <Checkbox checked={showPaid} onChange={(event) => setShowPaid(event.checked || false)}/>
          <div className="pl-2">Toon reeds betaalde facturen</div>
        </div>


        <div className="mt-2 pt-2 pt-2 mb-2 border-top-1 border-100 border-bottom-1">
          <div className="font-medium">Algemene betalingsgegevens</div>
          <div className="pb-1">Groentegeweld</div>

          <div className="mt-2 grid text-sm grid-nogutter">
            <div className="col-5 text-color-secondary uppercase">Rekeningnummer</div>
            <div className="col-7 font-medium">BE46 5230 8118 5136</div>
            <div className="col-5 text-color-secondary uppercase">Mededeling</div>
            <div className="col-7">
              <div className="font-medium">F-xxxx-0000</div>
              <div className="text-xs text-color-secondary font-italic">Gelieve de correct factuurnummer te vermelden bij
                je betaling.
              </div>
            </div>

          </div>
        </div>
        {/*<Invoices>*/}
        {/*  <DataTable*/}
        {/*    className="sf-table"*/}
        {/*    //autoLayout*/}
        {/*    value={showPaid ? invoices : invoices.filter(invoice => invoice.status !== 'paid')}*/}
        {/*    selectionMode="single"*/}
        {/*    selection={selectedInvoice}*/}
        {/*    onSelectionChange={e => setSelectedInvoice(e.value as Invoice)}*/}
        {/*    dataKey="id"*/}
        {/*  >*/}
        {/*    <Column field={'number'} header={'Factuur'}/>*/}
        {/*    <Column header={'Factuurdatum'}*/}
        {/*            body={createdAtColumn}/>*/}
        {/*    <Column header={'Bedrag'}*/}
        {/*            body={(invoice: Invoice) => formatCurrency(invoice.data.totalIncl)}/>*/}
        {/*    <Column header={'Status'}*/}
        {/*            body={body}/>*/}
        {/*  </DataTable>*/}

        {/*  <div className="mx-1 text-xs flex align-items-center">*/}
        {/*    <Checkbox checked={showPaid} onChange={(event) => setShowPaid(event.checked || false)}/>*/}
        {/*    <div className="pl-2">Toon reeds betaalde facturen</div>*/}
        {/*  </div>*/}

        {/*  {selectedInvoice &&*/}
        {/*    <PDFViewer style={{height: '60vh', width: '100%'}}>*/}
        {/*      <ClientOrderInvoicePdf data={selectedInvoice}/>*/}
        {/*    </PDFViewer>*/}
        {/*  }*/}
        {/*</Invoices>*/}

        {/*{invoices.map(invoice => <Invoice>*/}
        {/*  {invoice.filename}*/}
        {/*</Invoice>)}*/}
      </div>
      {selectedInvoice &&
        <PDFViewer style={{height: '60vh', width: '100%'}}>
          <ClientOrderInvoicePdf data={selectedInvoice} farm={farm}/>
        </PDFViewer>
      }
    </div>;
  }
;

export default ClientsBillingPage;
