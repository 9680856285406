import gql from "graphql-tag";
import { BasketCompositionFieldsFragment } from "./basketComposition.gql";
import {ClientOrderFieldsFragment} from "./clientOrder.gql";
import {ProductFields_basicFragment} from "./product.gql";


export const ProductOrderFieldsFragment = gql`
fragment ProductOrderFields on ProductOrder {
  id
  portions
  usedPortions
}
`;

export const ProductOrderQuery = gql`
${ProductOrderFieldsFragment}
${ProductFields_basicFragment}
query ProductOrder($where: ProductOrderWhereInput!) {
  productOrders(where: $where) {
    ...ProductOrderFields
    deliveries {
      plannedDeliveryDate
      deliveryLocation
      basketComposition {
        id
      }
    }
    product {
      ...ProductFields_basic
    }
    clientOrder {
      deliveryDate
      deliveryEndDate
      client {
        name
        firstName
      }
    }
  }
}`;

export const ProductOrdersForBasketCompositionQuery = gql`
${ProductOrderFieldsFragment}
${ProductFields_basicFragment}
query ProductOrdersForBasketComposition(
  $basketCompositionId: ID,
  $plannedDeliveryStartDate: DateTime!,
  $plannedDeliveryEndDate: DateTime!
) {
  productOrders(where: {
        product: {
          code_starts_with: "pakketten-"
        },
        OR: [{
          deliveries_some: {
            plannedDeliveryDate_gte: $plannedDeliveryStartDate,
            plannedDeliveryDate_lte: $plannedDeliveryEndDate
            cancelled: false
          }
        },
          {
            deliveries_some: {
              basketComposition: {id: $basketCompositionId}
              cancelled: false
            }
          }
        ]
      }
  ) {
    ...ProductOrderFields
    deliveries(where: {cancelled: false}) {
      id
      plannedDeliveryDate
      deliveryLocation
      deliveryDate
      cancelled
      basketComposition {
        id
      }
    }

    plannedDeliveryForBasketComposition: deliveries(
      where: {
        OR: [
          {
            plannedDeliveryDate_gte: $plannedDeliveryStartDate,
            plannedDeliveryDate_lte: $plannedDeliveryEndDate
            cancelled: false
          },
          {
            basketComposition: {id: $basketCompositionId}
            cancelled: false
          }
        ]
      }
    ) {
      id
      plannedDeliveryDate
      deliveryLocation
      deliveryDate
      cancelled
      deliveredProductIds
      undeliverableProductIds
      basketComposition {
        id
        items {
          product { id }
          quantitySmall
          portionsSmall
          deliveryLocationsSmall
          quantityLarge
          portionsLarge
          deliveryLocationsLarge
        }
      }
    }


    product {
      ...ProductFields_basic
      name
      portionWillem
    }
    clientOrder {
      id
      client {
        firstName
        name
        email
      }
    }
  }
}`;

export const ProductOrderUpdateMutation = gql`
${ProductOrderFieldsFragment}
mutation ProductOrderUpdate(
  $where: ProductOrderWhereUniqueInput!
  $data: ProductOrderUpdateInput!
){
  updateProductOrder(
    where: $where
    data: $data
  ) {
    ...ProductOrderFields
  }
}`;

export const ProductOrderUpsertMutation = gql`
${ProductOrderFieldsFragment}
mutation ProductOrderUpsert(
  $where: ProductOrderWhereUniqueInput!
  $create: ProductOrderCreateInput!
  $update: ProductOrderUpdateInput!
){
  upsertProductOrder(
    where: $where
    create: $create
    update: $update
  ) {
    ...ProductOrderFields
  }
}
`;

export const ProductOrderDeleteMutation = gql`
mutation ProductOrderDelete(
  $id: ID!
){
  deleteManyProductOrderDeliveries(where: {productOrder: {id: $id}}) {
    count
  }
  deleteProductOrder(
    where: {id: $id}
  ) {
    id
  }
}
`;

export const DeliveriesMoveMutation = gql`
mutation DeliveriesMove(
  $deliveryIds: [ID!]!
  $plannedDeliveryDate: DateTime!
) {
  updateManyProductOrderDeliveries(
    where: {id_in: $deliveryIds}
    data: {
      plannedDeliveryDate: $plannedDeliveryDate
    }
  ) {
    count
  }
}`;

export const DeliveriesForDeliveryWeekQuery = gql`
${BasketCompositionFieldsFragment}
${ProductFields_basicFragment}
query DeliveriesForDeliveryWeek(
  $deliveryWeekStart: DateTime!
  $deliveryWeekEnd: DateTime!
) {
  basketCompositions(where: {
    deliveryWeek_gte: $deliveryWeekStart
    deliveryWeek_lte: $deliveryWeekEnd
  }) {
    ...BasketCompositionFields
  }
  shopOrders(where: {
    deliveryDate_gte: $deliveryWeekStart
    deliveryDate_lte: $deliveryWeekEnd
  }) {
    id
    orderDate
    deliveryDate
    deliveryLocation
    paid
    status
    extraPackaging
    promocode
    createdAt
    shopClient {
      id
      phone
      name
      email
      firstName
    }
    shopOrderItems {
      id
      delivered
      product {
        ...ProductFields_basic
        image
        name
        amount
        unit
        availableAsAlternativeUnitFor
        alternativeUnit
        avgWeight
        category {
          name
        }
      }
      currentPromo { id }
      amount
      priceIncl
    }
  }
  productOrderDeliveries(where:{
    plannedDeliveryDate_gte: $deliveryWeekStart
    plannedDeliveryDate_lte: $deliveryWeekEnd
    cancelled: false
    productOrder: {
      product: {
        type: SUBSCRIPTION
        category: {
          code_not_in: ["ambassadeur", "leeggoed", "bloemen"]
        }
      }
    }
  }){
    id
    plannedDeliveryDate
    deliveryDate
    deliveryLocation
    deliveredProductIds
    undeliverableProductIds
    productOrder {
      quantity
      product {
        ...ProductFields_basic
        name
        amount
        unit
        availableAsAlternativeUnitFor
        alternativeUnit
        avgWeight
        category { name }
      }
      clientOrder {client {id name firstName}}
    }
    basketComposition {
      id
    }
  }
  clientOrders(
    where: {
      deliveryDate_gte: $deliveryWeekStart
      deliveryDate_lte: $deliveryWeekEnd
      productOrders_none: {
        product: { type: SUBSCRIPTION }
        deliveries_some: {
          plannedDeliveryDate_not: null
        }
      }
    }) {
    id
    client {id name firstName group {id}}
    status
    deliveryDate
    productOrders {
      deliveryDate
      quantity
      product {
        ...ProductFields_basic
        name
        amount
        unit
        availableAsAlternativeUnitFor
        alternativeUnit
        avgWeight
        category { name }
      }
    }
  }
}
`;

export const ClientWithDeliveriesForPackagingQuery = gql`
${BasketCompositionFieldsFragment}
${ProductFields_basicFragment}
query ClientWithDeliveriesForPackaging(
  $clientId: ID!
  $deliveryWeekStart: DateTime!
  $deliveryWeekEnd: DateTime!
) {
  client(where: {id: $clientId}) {
    id
    name
    firstName
  }
  basketCompositions(where: {
    deliveryWeek_gte: $deliveryWeekStart
    deliveryWeek_lte: $deliveryWeekEnd
  }) {
    id
  }

  shopOrders(where: {
    shopClient:{id: $clientId}
    deliveryDate_gte: $deliveryWeekStart
    deliveryDate_lte: $deliveryWeekEnd
  }) {
    id
    orderDate
    deliveryDate
    deliveryLocation
    paid
    status
    extraPackaging
    createdAt
    shopClient {
      id
      phone
      name
      email
      firstName
    }
    shopOrderItems {
      id
      delivered
      product {
        ...ProductFields_basic
        image
        name
        amount
        unit
        category {
          code
          name
        }
      }
      currentPromo { id }
      amount
      priceIncl
    }
  }

  productOrders(
    where: {
      clientOrder: {client:{id: $clientId}}
      ambassadorDiscount: false
      product: {
        OR: [
         {
          type: SUBSCRIPTION
          category: {
            code_not_in: ["ambassadeur", "leeggoed", "zelfoogst"]
          }
         },
         { type: SIMPLE}
        ]
      }
      OR: [
        {
          clientOrder: {
          	deliveryDate_gte: $deliveryWeekStart
        		deliveryDate_lte: $deliveryWeekEnd
            productOrders_some: {
              product: {type: SIMPLE}
            },
          }
        },
        {
          product: {type: SUBSCRIPTION}
          deliveries_some: {
            plannedDeliveryDate_gte: $deliveryWeekStart
    				plannedDeliveryDate_lte: $deliveryWeekEnd
            cancelled: false
          }
        }
      ]
    }
  ) {
    id
    quantity
    product {
      ...ProductFields_basic
      type
      image
      avgWeight
      alternativeUnit
      availableAsAlternativeUnitFor
      name
      amount
      unit
      category { code name } }
      deliveryDate
      usedPortions
      portions
      clientOrder { id client { email group { id }}
    }
    allDeliveries: deliveries(where: {cancelled: false}, orderBy: plannedDeliveryDate_ASC) {
      id
      plannedDeliveryDate
      deliveryDate
      deliveryLocation
      cancelled
      basketComposition { id }
    }
    nextDelivery: deliveries(
      where: {
        plannedDeliveryDate_gte: $deliveryWeekStart,
    		plannedDeliveryDate_lte: $deliveryWeekEnd,
        cancelled: false
      }
    ) {
      id
      plannedDeliveryDate
      deliveryDate
      cancelled
      deliveryLocation
      deliveredProductIds
      undeliverableProductIds
      basketComposition {
        ...BasketCompositionFields
      }
    }
  }
}`;

export const DeliveryUpdateMutation = gql`
mutation DeliveryUpdate($id: ID!, $data: ProductOrderDeliveryUpdateInput!) {
  updateProductOrderDelivery(
    where: {id: $id}
    data: $data
  ) {
    id
  }
}`;

export const DeliveriesUpdateMutation = gql`
mutation DeliveriesUpdate(
  $where: ProductOrderDeliveryWhereInput!,
  $data: ProductOrderDeliveryUpdateManyMutationInput!) {
  updateManyProductOrderDeliveries(
    where: $where
    data: $data
  ) {
    count
  }
}`;
