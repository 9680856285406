import { DeliveryLocation } from "../../__generated__/types";

export interface DeliveryLocationDetail {
  label: string;
  name: string;
  address: string;
  city: string;
  value: DeliveryLocation;
  pickupDay: number;
  logo: string;
  shopDays: number[];
  shopHours: string[];
  color: string;
}
export const deliveryLocations: DeliveryLocationDetail[] = [
  {
    label: 'MERKSPLAS: Center Peeraer, Steenweg op Turnhout 74',
    name: 'Center Peeraer',
    address: 'Steenweg op Turnhout 74',
    city: 'Merksplas',
    value: DeliveryLocation.MERKSPLAS,
    //deprecated
    pickupDay: 5,
    logo: 'centerPeeraer.png',
    shopDays: [5],
    shopHours: ['van 15u30 tot 18u00'],
    color: 'delivery-location-1'
  },
  {
    label: 'ARENDONK: Dirk Hendrix, Hertevelden 13',
    city: 'Arendonk',
    value: DeliveryLocation.ARENDONK,
    pickupDay: 5,
    name: 'Dirk Hendrix',
    address: 'Hertevelden 13',
    logo: 'groentegeweld.jpeg',
    shopDays: [1, 3, 5],
    shopHours: ['van 16u00 tot 21u00'],
    color: 'delivery-location-2'
  },
  {
    label: 'WEELDE: Groentegeweld, Weeldestraat 168',
    city: 'Weelde',
    value: DeliveryLocation.WEELDE,
    pickupDay: 5,
    name: 'Groentegeweld',
    address: 'Weeldestraat 168',
    logo: 'groentegeweld.jpeg',
    shopDays: [1, 3, 5, 6],
    shopHours: ['van 13u00 tot 21u00'],
    color: 'delivery-location-3',
  },
  {
    label: 'OUD-TURNHOUT: Omloop, Kerkstaat 1',
    name: 'Omloop',
    address: 'Kerkstaat 1',
    city: 'Oud-Turnhout',
    value: DeliveryLocation.OUD_TURNHOUT,
    pickupDay: 5,
    logo: 'omloop.png',
    shopDays: [5],
    shopHours: ['van 15u30 tot 18u00'],
    color: 'delivery-location-4'
  },
  {
    label: 'TURNHOUT: ToBe Kempen, Hendrik Consciencestraat 20',
    name: 'ToBe Kempen',
    address: 'Hendrik Consciencestraat 20',
    city: 'Turnhout',
    value: DeliveryLocation.TURNHOUT,
    pickupDay: 5,
    logo: 'tobe.jpeg',
    shopDays: [5],
    shopHours: ['van 15u00 tot 21u00'],
    color: 'delivery-location-5'
  },
  {
    label: 'RAVELS: Oxfam Wereldwinkel, Kerkstraat 7',
    city: 'Ravels',
    value: DeliveryLocation.RAVELS,
    pickupDay: 5,
    name: 'Oxfam Wereldwinkel',
    address: 'Kerkstraat 7',
    logo: 'wereldwinkelRavels.jpg',
    shopDays: [5],
    shopHours: ['van 15u00 tot 17u00'],
    color: 'delivery-location-6'
  },
  {
    label: 'BAARLE-NASSAU: Kukelekoe, Heikant 7',
    name: 'Kukelekoe',
    address: 'Heikant 7',
    city: 'Baarle-Nassau',
    value: DeliveryLocation.BAARLE_NASSAU,
    pickupDay: 5,
    logo: 'kukelekoe.png',
    shopDays: [5],
    shopHours: ['van 14u30 tot 17u00'],
    color: 'delivery-location-7'
  },
];

export const toReadableDeliveryLocation = (deliveryLocation: DeliveryLocation) => {
  return deliveryLocations.find(dl => dl.value === deliveryLocation)?.label;
};

export const toCity = (deliveryLocation: DeliveryLocation | null | undefined) => {
  let city;
  if (deliveryLocation) {
    city = deliveryLocations.find(dl => dl.value === deliveryLocation)?.city;
  }
  return city || 'onbekend';
};
export const toTag = (deliveryLocation: DeliveryLocation | null | undefined) => `dorp_${toCity(deliveryLocation).toLowerCase()}`;

export const toNameAndCity = (deliveryLocation: DeliveryLocation | null) => {
  let nameAndCity;
  if (deliveryLocation) {
    const deliveryLocationData = deliveryLocations.find(dl => dl.value === deliveryLocation);
    nameAndCity = `${deliveryLocationData?.name}, ${deliveryLocationData?.city}`;
  }
  return nameAndCity || 'onbekend';
};

export const toPickupDay = (deliveryLocation: DeliveryLocation) => {
  return deliveryLocations.find(dl => dl.value === deliveryLocation)?.pickupDay;
};

export const toColor = (deliveryLocation: DeliveryLocation) => {
  return deliveryLocations.find(dl => dl.value === deliveryLocation)?.color;
};

export const deliveryLocationValueToDetail = (deliveryLocation: DeliveryLocation) => {
  return deliveryLocations.find(dl => dl.value === deliveryLocation);
};
