import React, {useEffect, useState} from 'react';
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import ClientBasketDashboard from "../../StartPage/Baskets/ClientBasketDashboard/ClientBasketDashboard";
import {useClientObject} from "../../../ClientContext";
import {useAuth0} from "@auth0/auth0-react";
import AdminClientSelector from './AdminClientSelector';
import FlexSubscriptionStatusOverview from "../ClientBasketFlexPage/components/FlexSubscriptionStatusOverview";
import CustomerBasketComposition from "../ClientBasketFlexPage/components/CustomerBasketComposition";
import {id} from "chartjs-plugin-trendline";
import {useQuery} from "@apollo/client";
import {ClientOrder} from "../../../__generated__/types";
import {ClientOrderQuery} from "../../../shared/queries/clientOrder.gql";
import {first, sortBy} from "lodash";

const ClientBasketPage = () => {
  const {client} = useClientObject();
  const {user} = useAuth0();
  const navigate = useNavigate();
  const [clientId, setClientId] = useState<string>();
  let id = first(sortBy(client?.clientOrders, 'deliveryDate', 'desc'))?.id;
  console.log(id);
  //const {data} = useQuery<ClientOrder>(ClientOrderQuery, {variables: {id: clientOrderId}});

  useEffect(() => {
    if (client?.id) {
      setClientId(client.id);
    } else {
      setClientId(undefined);
    }
  }, [client]);


  return <div>
    {(user && (user.email === 'vpeltwillem@gmail.com' || user.email === 'dirkhendrix13@gmail.com')) &&
      <AdminClientSelector clientId={clientId} onChange={(clientId) => setClientId(clientId)}/>
    }

    <div className="grid p-2">
      <div className="col-12 md:col-5 lg:col-3 border-right-1 border-300 pr-2 p-0">
        <div>
          {id && <FlexSubscriptionStatusOverview
            clientOrderId={id}
            //deliveryDate={firstUndelivered?.plannedDeliveryDate}
          />}
        </div>
      </div>
      <div className="col-12 md:col-7 lg:col-9">
        <div className="m-1 md:m-2 border-bottom-1 border-300 p-1 md:p-2 flex justify-content-between align-items-center">
          <div className="text-xl md:text-2xl">Mijn pakket</div>
          <div>
            <Button className="p-0 p-button-link text-sm md:text-md" label={"Beheer mijn pakketleveringen"} onClick={() => {
              navigate('/mijnpakket/leveringen');
            }}/>
          </div>
        </div>
        {clientId && <ClientBasketDashboard clientId={clientId}/>}
      </div>
    </div>

  </div>;
};

export default ClientBasketPage;
