import React from 'react';
import {
  ClientOrderUpdate,
  ClientOrderUpdateVariables,
  ClientWithDeliveriesForPackaging_productOrders,
  ClientWithDeliveriesForPackaging_productOrders_nextDelivery,
  DeliveryUpdate,
  DeliveryUpdateVariables,
  GetProducts_products,
  ProductOrderUpdate,
  ProductOrderUpdateVariables
} from "../../../../__generated__/types";
import {compact, first, isEqual, map, orderBy, uniq, without} from "lodash";
import {getBasketSize, portionsFieldKeyForSize, quantityFieldKeyForSize} from "../../../../shared/utils/product.utils";
import {isBasketProductOrder} from "../../../../utils/productOrder.utils";
import ProductPackagingView from "./ProductPackagingView";
import {useMutation} from "@apollo/client";
import {DeliveryUpdateMutation, ProductOrderUpdateMutation} from "../../../../shared/queries/productOrder.gql";
import {asIso8601} from "../../../../shared/utils/date.utils";
import moment, {Moment} from "moment";
import {ClientOrderUpdateMutation} from "../../../../shared/queries/clientOrder.gql";
import {TODAY} from "../../../../shared/config";
import {basketItemsForDelivery, totalPortions} from "../../../../shared/utils/basketComposition.utils";
import {formatDoubleDigit} from "../../../../shared/utils/currency.utils";

interface ProductOrderPackagingViewProps {
  products: GetProducts_products[];
  productOrder: ClientWithDeliveriesForPackaging_productOrders;
  deliveryWeek: Moment;
}

const ProductOrderPackagingView = (props: ProductOrderPackagingViewProps) => {
    const [updateDelivery] = useMutation<DeliveryUpdate>(DeliveryUpdateMutation);
    const [updateProductOrder] = useMutation<ProductOrderUpdate>(ProductOrderUpdateMutation);
    const [updateClientOrder] = useMutation<ClientOrderUpdate>(ClientOrderUpdateMutation);

    const {productOrder} = props;

    let delivery = first(productOrder.nextDelivery);
    let basketCompositionItems = compact(delivery?.basketComposition?.items);
    let basketSize = getBasketSize(productOrder.product);
    let quantityFieldKey = quantityFieldKeyForSize(basketSize);
    let portionsFieldKey = portionsFieldKeyForSize(basketSize);
    let basketProductOrder = isBasketProductOrder(productOrder);

    const onDeliveryDelivered = (
      productOrder: ClientWithDeliveriesForPackaging_productOrders,
      delivery: ClientWithDeliveriesForPackaging_productOrders_nextDelivery,
      product: {
        id: string
      },
      portions: number,
    ) => (isDelivered: boolean) => {
      if (delivery?.id) {
        let updatedDeliveredProductIds;
        let updatedUndeliverableProductIds = delivery.undeliverableProductIds;

        let updatedUsedPortions = productOrder.usedPortions;
        let productIsMarkedAsDelivered = delivery.deliveredProductIds.includes(product.id);
        if (!productIsMarkedAsDelivered && isDelivered) {
          updatedUsedPortions = (productOrder.usedPortions || 0) + portions;
        }

        if (productIsMarkedAsDelivered && !isDelivered) {
          updatedUsedPortions = (productOrder.usedPortions || 0) - portions;
        }

        if (isDelivered) {
          updatedDeliveredProductIds = [...delivery.deliveredProductIds, product.id]
          updatedUndeliverableProductIds = without(delivery.undeliverableProductIds, product.id)
        } else {
          updatedDeliveredProductIds = without(delivery.deliveredProductIds, product.id)
        }
        updatedDeliveredProductIds = uniq(updatedDeliveredProductIds);

        let basketItems = basketItemsForDelivery(basketCompositionItems, productOrder.product, delivery.deliveryLocation);

        let productIdsOfBasketItems = map(basketItems, 'product.id');
        updatedDeliveredProductIds = updatedDeliveredProductIds.filter(productId => productIdsOfBasketItems.includes(productId));

        let deliveryDate = null;

        let basketItemProductIds = [...uniq(productIdsOfBasketItems)];
        let deliveredAndUndeliverableProductIds = [...updatedDeliveredProductIds, ...updatedUndeliverableProductIds];
        if ((basketProductOrder && isEqual([...deliveredAndUndeliverableProductIds].sort(), [...basketItemProductIds].sort())
          || (!basketProductOrder && isEqual(deliveredAndUndeliverableProductIds, [product.id])))) {
          deliveryDate = asIso8601(moment());
        }

        const variables2: ProductOrderUpdateVariables = {
          where: {id: productOrder.id},
          data: {
            usedPortions: updatedUsedPortions,
            deliveries: {
              update: [{
                where: {id: delivery?.id},
                data: {
                  deliveryDate,
                  undeliverableProductIds: {
                    set: updatedUndeliverableProductIds,
                  },
                  deliveredProductIds: {
                    set: updatedDeliveredProductIds,
                  }
                },
              }]
            }
          }
        }

        updateProductOrder({variables: variables2, refetchQueries: "active"});

      }
    };

    const onToggleUndeliverable = (
      productOrder: ClientWithDeliveriesForPackaging_productOrders,
      delivery: ClientWithDeliveriesForPackaging_productOrders_nextDelivery,
      product: {
        id: string
      },
      portions: number,
    ) => (isUndeliverable: boolean) => {
      if (delivery?.id) {
        let updatedUndeliverableProductIds;
        let updatedDeliveredProductIds = delivery.deliveredProductIds;

        let updatedUsedPortions = productOrder.usedPortions;
        let productIsMarkedAsDelivered = delivery.deliveredProductIds.includes(product.id);
        if (productIsMarkedAsDelivered && isUndeliverable) {
          updatedUsedPortions = (productOrder.usedPortions || 0) - portions;
        }

        if (isUndeliverable) {
          updatedUndeliverableProductIds = [...delivery.undeliverableProductIds, product.id]
          updatedDeliveredProductIds = without(delivery.deliveredProductIds, product.id)
        } else {
          updatedUndeliverableProductIds = without(delivery.undeliverableProductIds, product.id)
        }
        updatedUndeliverableProductIds = uniq(updatedUndeliverableProductIds);

        let basketItems = basketItemsForDelivery(basketCompositionItems, productOrder.product, delivery.deliveryLocation);

        let productIdsOfBasketItems = map(basketItems, 'product.id');
        updatedUndeliverableProductIds = updatedUndeliverableProductIds.filter(productId => productIdsOfBasketItems.includes(productId));

        let deliveryDate = null;

        let basketItemProductIds = [...uniq(productIdsOfBasketItems)];
        let deliveredAndUndeliverableProductIds = [...updatedDeliveredProductIds, ...updatedUndeliverableProductIds];
        if ((basketProductOrder && isEqual([...deliveredAndUndeliverableProductIds].sort(), [...basketItemProductIds].sort()))
          || (!basketProductOrder && isEqual(deliveredAndUndeliverableProductIds, [product.id]))) {
          deliveryDate = asIso8601(moment());
        }

        const variables2: ProductOrderUpdateVariables = {
          where: {id: productOrder.id},
          data: {
            usedPortions: updatedUsedPortions,
            deliveries: {
              update: [{
                where: {id: delivery?.id},
                data: {
                  deliveryDate,
                  undeliverableProductIds: {
                    set: updatedUndeliverableProductIds,
                  },
                  deliveredProductIds: {
                    set: updatedDeliveredProductIds,
                  }
                },
              }]
            }
          }
        }

        updateProductOrder({variables: variables2, refetchQueries: "active"});
      }
    };

    const onExtraProductDeliveryDelivered = (
      delivery: ClientWithDeliveriesForPackaging_productOrders_nextDelivery,
      product: {
        id: string
      } | null,
    ) => (isDelivered: boolean) => {
      if (delivery?.id && product) {
        let updatedDeliveredProductIds;
        if (isDelivered) {
          updatedDeliveredProductIds = [...delivery.deliveredProductIds, product.id]
        } else {
          updatedDeliveredProductIds = without(delivery.deliveredProductIds, product.id)
        }
        updatedDeliveredProductIds = uniq(updatedDeliveredProductIds);

        let deliveryDate = null;
        if ((isEqual(updatedDeliveredProductIds, [product.id]))) {
          deliveryDate = asIso8601(moment());
        }
        const variables: DeliveryUpdateVariables = {
          id: delivery?.id,
          data: {
            deliveryDate,
            deliveredProductIds: {
              set: updatedDeliveredProductIds,
            }
          },
        };
        updateDelivery({variables, refetchQueries: "active"});
      }
    };

    const onProductOrderDelivered = (
      productOrder: {
        id: string
      },
    ) => (isDelivered: boolean) => {

      // const variables: ProductOrderUpdateVariables = {
      //   data: {
      //     deliveryDate: isDelivered ? TODAY : null,
      //   },
      //   where: {id: productOrder.id}
      // };

      const variables: ClientOrderUpdateVariables = {
        data: {
          productOrders: {
            update: [{
              where: {id: productOrder.id},
              data: {deliveryDate: isDelivered ? TODAY : null}
            }]
          }
        },
        where: {id: props.productOrder.clientOrder.id}
      };

      updateClientOrder({variables, refetchQueries: "active"});
    };

    let totalDeliveries = compact(productOrder.allDeliveries);
    let nrOfCurrentDelivery = totalDeliveries.findIndex(d => d.id === delivery?.id) + 1;

    return <div className="border-bottom-1 border-200 p-2">
      {delivery && !basketProductOrder && productOrder.product && <ProductPackagingView
        clientGroupId={productOrder.clientOrder.client.group?.id}
        onDelivered={onExtraProductDeliveryDelivered(delivery, productOrder.product)}
        isDelivered={compact(delivery?.deliveredProductIds).findIndex(id => id === productOrder.product?.id) > -1}
        isUndeliverable={false}
        product={productOrder.product}
        quantity={productOrder.quantity * (productOrder.product?.amount || 1)}/>}

      {!delivery && !basketProductOrder && productOrder.product && <ProductPackagingView
        clientGroupId={productOrder.clientOrder.client.group?.id}
        onDelivered={onProductOrderDelivered(productOrder)}
        isDelivered={productOrder.deliveryDate !== null}
        isUndeliverable={false}
        product={productOrder.product}
        quantity={productOrder.quantity}/>}

      {basketProductOrder && <div>
        <div className="flex justify-content-between">
          <div>{productOrder.product?.name}</div>
          <div>{nrOfCurrentDelivery} van {totalDeliveries.length} pakketten</div>
        </div>
        {orderBy(basketItemsForDelivery(basketCompositionItems, productOrder.product, delivery?.deliveryLocation), 'packagingOrder')
          .map(basketCompositionItem => {
              let product = props.products.find(p => p.id === basketCompositionItem.product.id);
              if (!product) {
                return null;
              }
              let quantity = quantityFieldKey ? basketCompositionItem[quantityFieldKey] : 0;
              let portions = portionsFieldKey ? basketCompositionItem[portionsFieldKey] : 0;
              if (!delivery || !quantity) {
                return null;
              }

              let productIsDelivered = compact(delivery?.deliveredProductIds).findIndex(id => id === product?.id) > -1;
              let productIsUndeliverable = compact(delivery?.undeliverableProductIds).findIndex(id => id === product?.id) > -1;

              return <ProductPackagingView
                clientGroupId={productOrder.clientOrder.client.group?.id}
                onDelivered={onDeliveryDelivered(productOrder, delivery, product, portions)}
                onToggleUndeliverable={onToggleUndeliverable(productOrder, delivery, product, portions)}
                isDelivered={productIsDelivered}
                isUndeliverable={productIsUndeliverable}
                product={product}
                quantity={quantity * (productOrder.product?.amount || 1)}
                portions={portions}
              />;
            }
          )}
        <div>{formatDoubleDigit(totalPortions(basketCompositionItems, basketSize, delivery?.deliveryLocation))} punten</div>
      </div>}
    </div>;
  }
;

export default ProductOrderPackagingView;
