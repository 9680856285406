import React from 'react';
import {Moment} from "moment";
import {useQuery} from "@apollo/client";
import {ClientWithDeliveriesForPackaging, GetProducts_products, ProductType} from "../../../../__generated__/types";
import {ClientWithDeliveriesForPackagingQuery} from "../../../../shared/queries/productOrder.gql";
import {endOfIsoWeek, startOfIsoWeek} from "../../../../shared/utils/date.utils";
import {compact, first, orderBy} from "lodash";
import {toCity} from "../../../../shared/utils/deliveryLocation.utils";
import {isBasketProductOrder} from "../../../../utils/productOrder.utils";
import ProductOrderPackagingView from './ProductOrderPackagingView';
import classNames from "classnames";
import {findBasketProductOrder, productOrdersAreDelivered} from "../../../../shared/utils/productOrder.utils";
import {basketProductOrderHasInsufficientPoints_error} from "../../../../utils/clientOrder.utils";
import FlexNotifications, {notificationsFor} from "../../../ClientBasketDeliveriesPage/ClientBasketFlexPage/components/FlexNotifications";

interface ClientOrderPackagingViewProps {
  deliveryWeek: Moment;
  clientId: string;
  products: GetProducts_products[];
}

const ClientOrderPackagingView = (props: ClientOrderPackagingViewProps) => {
  const {data} = useQuery<ClientWithDeliveriesForPackaging>(ClientWithDeliveriesForPackagingQuery, {
    //pollInterval: 3000,
    variables: {
      clientId: props.clientId,
      deliveryWeekStart: startOfIsoWeek(props.deliveryWeek),
      deliveryWeekEnd: endOfIsoWeek(props.deliveryWeek),
    }
  });

  let productOrders = compact(data?.productOrders);
  const deliveryLocation = first(first(productOrders)?.nextDelivery)?.deliveryLocation;
  let basketProductOrder = findBasketProductOrder(productOrders);
  //
  // let productOrderWithDeliveries = null;
  // if (basketProductOrder) {
  //   productOrderWithDeliveries = {...basketProductOrder, deliveries: basketProductOrder?.allDeliveries};
  //   // let flexNotifications = notificationsFor(productOrderWithDeliveries,0);
  //   // flexNotifications
  // }
  return <div
    className={
      classNames("border-1 border-300 border-round-lg p-2",
        {"border-green-300 bg-green-100": productOrdersAreDelivered(productOrders)},
        {"border-red-300 bg-red-100": basketProductOrder && basketProductOrderHasInsufficientPoints_error({...basketProductOrder, deliveries: basketProductOrder?.allDeliveries})})
    }>
    <div className="flex justify-content-between">
      <div className="text-xl font-bold">{data?.client?.firstName} {data?.client?.name}</div>
      <div className="text-500">{toCity(deliveryLocation)} <i className="pi pi-map-marker"/></div>
    </div>

    {/*{productOrderWithDeliveries && <FlexNotifications*/}
    {/*  basketProductOrder={productOrderWithDeliveries}*/}
    {/*  clientEmail={productOrderWithDeliveries.clientOrder.client.email}*/}
    {/*  clientOrderId={productOrderWithDeliveries.clientOrder.id}*/}
    {/*  nextDelivery={null}*/}
    {/*  additionalPortions={0}*/}
    {/*/>}*/}

    <div className="p-2">
      <div className="text-lg font-medium">Productenlijst</div>
      <div className="mt-2">
        {orderBy(productOrders, (productOrder => isBasketProductOrder(productOrder)))
          .filter(productOrder => productOrder.product?.category?.code !== 'bloemen')
          .filter(productOrder => {
            let nextDelivery = compact(productOrder.nextDelivery);
            return productOrder.product?.type === ProductType.SIMPLE
              || (productOrder.product?.type === ProductType.SUBSCRIPTION && nextDelivery.length > 0);
          })
          .map(productOrder => {
            let productOrderWithDeliveries = null;
            if (isBasketProductOrder(productOrder)) {
              productOrderWithDeliveries = {...productOrder, deliveries: productOrder?.allDeliveries};
              // let flexNotifications = notificationsFor(productOrderWithDeliveries,0);
              // flexNotifications
            }
            return <div>
              {/*{productOrderWithDeliveries && <FlexNotifications*/}
              {/*  basketProductOrder={productOrderWithDeliveries}*/}
              {/*  clientEmail={productOrderWithDeliveries.clientOrder.client.email}*/}
              {/*  clientOrderId={productOrderWithDeliveries.clientOrder.id}*/}
              {/*  nextDelivery={null}*/}
              {/*  additionalPortions={0}*/}
              {/*/>}*/}
              <ProductOrderPackagingView products={props.products} deliveryWeek={props.deliveryWeek} productOrder={productOrder}/>
            </div>;
          })}
      </div>
    </div>
  </div>;
};

export default ClientOrderPackagingView;
