import React, {useState} from 'react';
import {Moment} from "moment";
import {useQuery} from "@apollo/client";
import {DeliveriesForDeliveryWeekQuery} from "../../../../shared/queries/productOrder.gql";
import {
  DeliveriesForDeliveryWeek,
  DeliveriesForDeliveryWeek_basketCompositions_items,
  DeliveriesForDeliveryWeek_productOrderDeliveries,
  DeliveriesForDeliveryWeek_shopOrders_shopOrderItems,
  DeliveryLocation,
  GetProducts,
  GetProductsVariables,
  ProductUnit
} from "../../../../__generated__/types";
import {asIso8601} from "../../../../shared/utils/date.utils";
import {chain, compact, flatten, map, set, sum, uniq, values} from "lodash";
import {amountWithUnit} from "../../../../shared/utils/unit.utils";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {
  BasketSize,
  deliveryLocationsFieldKeyForSize,
  productIsAvailableAsAlternativeUnitFor,
  quantityFieldKeyForSize
} from "../../../../shared/utils/product.utils";
import {useStickyState} from "../../../../utils/useStateWithSessionStorage";
import {ToggleButton} from "primereact/togglebutton";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import AmountCell from "./AmountCell";
import {GetProductsQuery} from "../../../../shared/queries/product.gql";
import {useUserObject} from "../../../../shared/context/UserContext";
import {Checkbox} from "primereact/checkbox";

interface HarvestViewProps {
  deliveryWeek: Moment;
  flexClientGroupCode: string;
}

export type HarvestAmount = {
  [key in ProductUnit]: {
    productAmount: number;
    orderAmount: number;
    referenceIds: string[];
  }[];
}

interface ProductWithHarvestAmount {
  id: string;
  name: string;
  amount: number | null;
  unit: ProductUnit | null;
  availableAsAlternativeUnitFor: string[] | null;
  avgWeight?: number | undefined | null;
  category?: {
    name: string,
    code: string | null
  } | null;
  extraDeliveries: DeliveriesForDeliveryWeek_productOrderDeliveries[];
  harvestAmounts: {
    amountForBasketCompositions: HarvestAmount,
    amountForExtraProducts: HarvestAmount;
    amountForOtherClientOrders: HarvestAmount;
    amountForShopOrders: HarvestAmount;
  };
  harvestMeta?: {
    assignee: string[];
    isDone: boolean;
  }
}

const HarvestView = (props: HarvestViewProps) => {
  const {deliveryWeek, flexClientGroupCode} = props;
  const {activeFarm} = useUserObject();
  const [showDeliveredProducts, setShowDeliveredProducts] = useState<boolean>(false);
  const {data} = useQuery<DeliveriesForDeliveryWeek>(DeliveriesForDeliveryWeekQuery, {
    variables: {
      deliveryWeekStart: deliveryWeek ? asIso8601(deliveryWeek.clone().startOf('isoWeek')) : null,
      deliveryWeekEnd: deliveryWeek ? asIso8601(deliveryWeek.clone().endOf('isoWeek')) : null,
    }
  });

  const getProductsVariables: GetProductsVariables = {
    where: {},
    farmId: activeFarm?.id || '',
    clientGroupCode: flexClientGroupCode,
  };

  const {data: productsData} = useQuery<GetProducts>(GetProductsQuery, {
    variables: getProductsVariables
  });

  const [harvestItemsMeta, setHarvestItemsMeta] = useStickyState({}, "harvestItemsMeta");

  let deliveries = compact(data?.productOrderDeliveries);
  //let basketCompositions: DeliveriesForDeliveryWeek_basketCompositions[] = [];
  let basketCompositions = compact(data?.basketCompositions);
  //let otherClientOrders: DeliveriesForDeliveryWeek_clientOrders[] = [];
  let otherClientOrders = compact(data?.clientOrders);
  let shopOrders = compact(data?.shopOrders);

  const isDelivered = (item: {
                         deliveryDate?: string | null | undefined,
                         delivered?: boolean | undefined
                       },
  ): boolean => {
    return (!!item.deliveryDate || !!item.delivered);
  };

  const shouldIncludeIfProductIsDelivered = (item: {
    deliveryDate?: string | null | undefined;
    delivered?: boolean | undefined
  }): boolean => {
    // if (isDelivered(item)) {
    //   return showDeliveredProducts;
    // } else {
    //   return true;
    // }

    return !isDelivered(item) || showDeliveredProducts;
  };

  let productIdsFromExtraDeliveries: string[] = compact(uniq(
    deliveries
      .filter(shouldIncludeIfProductIsDelivered)
      .filter(delivery => delivery.productOrder.product?.category?.code
        && delivery.productOrder.product.category?.code !== 'pakketten'
        && delivery.productOrder.product.category?.code !== 'zelfoogst'
        && delivery.productOrder.product.category?.code !== 'bloemen'
      )
      .map(delivery => delivery.productOrder.product?.id)
    //.filter(product => product.name.indexOf("gemengd - 1,25kg") > -1)
  ));

  let basketCompositionProducts = chain(basketCompositions)
    .map(basketComposition => flatten(compact(basketComposition.items).map(item => item.product.id)))
    .flatten()
    .value();

  let otherClientOrderProducts = compact(flatten(otherClientOrders
    .map(clientOrder => map(
      compact(clientOrder.productOrders)
        .filter(shouldIncludeIfProductIsDelivered),
      "product.id")
    ))
    .filter(product => !product.category || (product?.category
      && product.category.code !== 'leeggoed'
      //     && product.category.code !== "pakketten"
      //     && product.category.code !== 'zelfoogst'
      //     && product.category.code !== 'bloemen'
    ))
  );

  let shopOrderProducts = flatten(
    shopOrders
      .map(shopOrder => map(
        compact(shopOrder.shopOrderItems)
          .filter(shouldIncludeIfProductIsDelivered),
        "product.id"
      ))
  );
  const allProductIds = uniq([
    ...productIdsFromExtraDeliveries,
    ...basketCompositionProducts,
    ...otherClientOrderProducts,
    ...shopOrderProducts,
  ]);

  let productsFromExtraDeliveries: {
    id: string,
    name: string,
    amount: number | null,
    unit: ProductUnit | null,
    availableAsAlternativeUnitFor: string[] | null;
    alternativeUnit: ProductUnit | null,
    avgWeight?: number | undefined | null;
    category?: {
      name: string,
      code: string | null
    } | null
  }[] = compact(productsData?.products).filter(p => allProductIds.includes(p.id));

  let reduceToOrderAmounts = (amountsPerUnit: HarvestAmount,
                              productOrder: {
                                unit: ProductUnit | null | undefined,
                                productOrderAmount: number,
                                productAmount: number | null | undefined,
                                referenceIds: string[],
                              }) => {
    const {unit, productOrderAmount, productAmount} = productOrder;
    if (unit && productOrderAmount > 0) {
      let idxOfMatchingProductQuantity = amountsPerUnit[unit].findIndex(x => x.productAmount === productAmount);
      let currentOrderAmount = amountsPerUnit[unit][idxOfMatchingProductQuantity]?.orderAmount;
      let currentReferenceIds = amountsPerUnit[unit][idxOfMatchingProductQuantity]?.referenceIds;

      if (idxOfMatchingProductQuantity > -1) {
        set(amountsPerUnit, `${unit}[${idxOfMatchingProductQuantity}].orderAmount`, (currentOrderAmount || 0) + productOrderAmount);
        set(amountsPerUnit, `${unit}[${idxOfMatchingProductQuantity}].referenceIds`, compact([...currentReferenceIds, ...productOrder.referenceIds]));
      } else {
        amountsPerUnit[unit].push({
          referenceIds: productOrder.referenceIds,
          orderAmount: productOrderAmount,
          productAmount: productAmount || 0,
        });
      }
    }
    return amountsPerUnit;
  };

  const deliveriesForBasketItem = (
    deliveries: DeliveriesForDeliveryWeek_productOrderDeliveries[],
    basketItem: DeliveriesForDeliveryWeek_basketCompositions_items | undefined,
    size: BasketSize,
  ) => {
    const field = deliveryLocationsFieldKeyForSize(size);

    if (size && field) {
      return deliveries
        .filter((delivery) => delivery.productOrder.product?.code && delivery.productOrder.product.code.indexOf(size) > -1)
        .filter(delivery => {
          if (!delivery.deliveryLocation) {
            return false;
          }
          let deliveryLocationsForBasketItem = basketItem && basketItem[field] ? compact(basketItem[field] as DeliveryLocation[]) : [];
          return deliveryLocationsForBasketItem.length === 0 || deliveryLocationsForBasketItem.includes(delivery.deliveryLocation);
        });
    }
    return [];
  };

  let productsWithAmounts: ProductWithHarvestAmount[] = chain(productsFromExtraDeliveries)
    .map(product => {
      let deliveriesForProduct = deliveries.filter(delivery => delivery.productOrder.product?.id === product.id);

      let basketCompositionsWithProduct = basketCompositions.filter(basketComposition => compact(basketComposition.items).filter(item => item.product.id === product.id).length > 0);

      let amountForBasketCompositions = chain(basketCompositionsWithProduct)
        .map(basketComposition => {
          let basketItem = compact(basketComposition.items).find(item => item.product.id === product.id);
          let deliveriesForBasketComposition = deliveries
            .filter(delivery => !delivery.deliveredProductIds.includes(product.id) || showDeliveredProducts)
            .filter(delivery => delivery.basketComposition?.id === basketComposition.id);

          return (["medium", "large"]).map((size) => {
            let quantityField = quantityFieldKeyForSize(size as BasketSize);
            if (quantityField && basketItem) {
              let quantityFieldValue = basketItem[quantityField];
              if (quantityFieldValue > 0) {
                let matchingDeliveries = deliveriesForBasketItem(deliveriesForBasketComposition, basketItem, size as BasketSize);
                let productOrderAmount = matchingDeliveries.length;
                let productAmount = quantityFieldValue || 0;
                let unit = product.unit;

                if (product.alternativeUnit && product.avgWeight && productIsAvailableAsAlternativeUnitFor(product, 'clg4w9j650a9u0775ttnk1w7o')) {
                  productAmount = quantityFieldValue / product.avgWeight;
                  unit = product.alternativeUnit;
                }
                return {productOrderAmount, productAmount, unit, referenceIds: map(matchingDeliveries, 'id')};
              }
            }
          });
          //
          //
          // let largeDeliveries = deliveriesForBasketItem(deliveriesForBasketComposition, basketItem, 'large');
          // let productOrderAmount = largeDeliveries;
          // let productAmount = basketItem?.quantityLarge || 0;
          // let unit = product.unit;
          //
          // if (product.alternativeUnit && product.avgWeight && productIsAvailableAsAlternativeUnitFor(product, 'clg4w9j650a9u0775ttnk1w7o')) {
          //   productOrderAmount = productOrderAmount / product.avgWeight;
          //   unit = product.alternativeUnit;
          // }
          // return {productOrderAmount, productAmount, unit};
        })
        .flatten()
        .compact()
        .reduce(reduceToOrderAmounts, {
          [ProductUnit.BUNCH]: [],
          [ProductUnit.KILOGRAMS]: [],
          [ProductUnit.PIECE]: [],
          [ProductUnit.GRAMS]: [],
        })
        .value();

      let amountForExtraProducts = deliveriesForProduct
        .filter(shouldIncludeIfProductIsDelivered)
        .map(delivery => {
          let productOrderAmount = delivery.productOrder.quantity;
          let productAmount = delivery.productOrder.product?.amount;
          let unit = product.unit;

          if (product.alternativeUnit && product.avgWeight && productIsAvailableAsAlternativeUnitFor(product, 'clg4w9j650a9u0775ttnk1w7o')) {
            productOrderAmount = productOrderAmount / product.avgWeight;
            unit = product.alternativeUnit;
          }
          return {productOrderAmount, productAmount, unit, referenceIds: [delivery.id]};
        })
        .reduce(reduceToOrderAmounts, {
          ...({
            [ProductUnit.BUNCH]: [],
            [ProductUnit.KILOGRAMS]: [],
            [ProductUnit.PIECE]: [],
            [ProductUnit.GRAMS]: [],
          })
        });

      let amountForOtherClientOrders = chain(otherClientOrders)
        .map(clientOrder =>
          compact(clientOrder.productOrders)
            .filter(shouldIncludeIfProductIsDelivered)
            .map(productOrder => ({...productOrder, clientOrder}))
        )
        .flatten()
        .filter((productOrder) => productOrder?.product?.id === product.id)
        .map(productOrder => {
          let productOrderAmount = productOrder.quantity;
          let productAmount = productOrder.product?.amount;
          let unit = product.unit;
          if (product.alternativeUnit && product.avgWeight && productIsAvailableAsAlternativeUnitFor(product, productOrder.clientOrder.client.group.id)) {
            productOrderAmount = productOrderAmount / product.avgWeight;
            unit = product.alternativeUnit;
          }
          return {productOrderAmount, productAmount, unit, referenceIds: [productOrder.clientOrder.id]};
        })
        .reduce(reduceToOrderAmounts, {
          ...({
            [ProductUnit.BUNCH]: [],
            [ProductUnit.KILOGRAMS]: [],
            [ProductUnit.PIECE]: [],
            [ProductUnit.GRAMS]: [],
          })
        })
        .value();

      let amountForShopOrders = chain(shopOrders)
        .map(shopOrder => compact(shopOrder.shopOrderItems).map(shopOrderItem => ({...shopOrderItem, shopOrder})))
        .flatten()
        .filter(shouldIncludeIfProductIsDelivered)
        .filter((shopOrderItem: DeliveriesForDeliveryWeek_shopOrders_shopOrderItems) => shopOrderItem?.product.id === product.id)
        .map(shopOrderItem => {
          let productOrderAmount = shopOrderItem.amount;
          let unit = product.unit;

          {/*HARDCODED ID*/
          }
          {/*HARDCODED ID*/
          }
          {/*HARDCODED ID*/
          }
          if (product.alternativeUnit && product.avgWeight && productIsAvailableAsAlternativeUnitFor(product, 'clg4w9j650a9u0775ttnk1w7o')) {
            productOrderAmount = productOrderAmount / product.avgWeight;
            unit = product.alternativeUnit;
          }
          return {
            productOrderAmount: 1,
            productAmount: productOrderAmount,
            unit,
            referenceIds: [shopOrderItem.shopOrder.id]
          };
        })
        .reduce(reduceToOrderAmounts, {
          ...({
            [ProductUnit.BUNCH]: [],
            [ProductUnit.KILOGRAMS]: [],
            [ProductUnit.PIECE]: [],
            [ProductUnit.GRAMS]: [],
          })
        })
        .value();

      return {
        ...product,
        extraDeliveries: deliveriesForProduct,
        harvestAmounts: {
          amountForBasketCompositions,
          amountForExtraProducts,
          amountForOtherClientOrders,
          amountForShopOrders,
        },
      };
    })
    .value();

  const amountCell = (
    amountField: 'amountForBasketCompositions' | 'amountForExtraProducts' | 'amountForOtherClientOrders' | 'amountForShopOrders'
  ) =>
    (productWithAmounts: ProductWithHarvestAmount) => {
      return <AmountCell product={productWithAmounts} field={amountField}/>;
    };

  return <div>
    <div className="flex align-items-center">
      <Button className="p-button-link px-0" label="Reset oogst status" onClick={() => setHarvestItemsMeta({})}/>
      <div className="pl-2 ml-2 mx-1 text-xs flex align-items-center">
        <Checkbox checked={showDeliveredProducts}
                  onChange={(event) => setShowDeliveredProducts(event.checked || false)}/>
        <div className="pl-2">Toon inclusief geleverde producten</div>
      </div>
    </div>
    <DataTable<ProductWithHarvestAmount[]>
      className="sf-table"
      tableClassName="w-auto"
      sortMode="multiple"
      multiSortMeta={[
        {field: 'name', order: 1},
      ]}
      sortOrder={1}
      value={productsWithAmounts.map(product => ({
        ...product,
        harvestMeta: {
          isDone: false,
          assignee: [],
          ...harvestItemsMeta[product.id],
        },
      }))}
    >
      <Column
        sortable
        header="Status"
        field="harvestMeta.isDone"
        bodyClassName="font-bold text-lg"
        body={(product: ProductWithHarvestAmount) => {
          return <div>
            <ToggleButton className={"p-dense"} onLabel="Geoogst" offLabel="Te oogsten" onIcon="pi pi-check"
                          offIcon="pi pi-times"
                          checked={product.harvestMeta?.isDone}
                          onChange={(e) =>
                            setHarvestItemsMeta(({
                              ...harvestItemsMeta,
                              [product.id]: {...harvestItemsMeta[product.id], isDone: e.value}
                            }))
                          }
            />
          </div>;
        }}
      />

      <Column
        sortable
        header="Uitvoerder"
        field="harvestMeta.assignee"
        bodyClassName="font-bold text-lg"
        body={(product: ProductWithHarvestAmount) => {
          return <div>
            <Dropdown
              className="p-dense max-w-8rem w-8rem"
              value={product.harvestMeta?.assignee}
              showClear
              options={[
                {value: 'Dirk', label: 'Dirk'},
                {value: 'Willem', label: 'Willem'},
                {value: 'Arne', label: 'Arne'},
                {value: 'Octavie', label: 'Octavie'},
                {value: 'Kathleen', label: 'Kathleen'},
                {value: 'Ludgarde', label: 'Ludgarde'},
                {value: 'Thomas', label: 'Thomas'},
                {value: 'Andere', label: 'Andere'},
              ]}
              onChange={(e) => {
                setHarvestItemsMeta(({
                  ...harvestItemsMeta,
                  [product.id]: {...harvestItemsMeta[product.id], assignee: e.value}
                }))
              }}/>
          </div>;
        }}
      />

      <Column sortable header="Naam" field="name" bodyClassName="font-bold text-lg"/>
      <Column
        header="Totaal"
        field="harvestAmounts"
        bodyClassName="font-bold text-lg border-0 border-right-1 border-solid border-500"
        body={(productWithAmounts) => {
          const totals = values(productWithAmounts.harvestAmounts)
            .reduce((totalAmountsPerUnit, harvestAmount) => {
              return [ProductUnit.BUNCH, ProductUnit.KILOGRAMS, ProductUnit.PIECE, ProductUnit.GRAMS].reduce((units, unit) => {
                return {
                  ...units,
                  [unit]: totalAmountsPerUnit[unit] + sum(harvestAmount[unit].map((x: {
                    productAmount: number;
                    orderAmount: number;
                  }) => x.orderAmount * x.productAmount)),
                };
              }, {});
            }, {
              [ProductUnit.BUNCH]: 0,
              [ProductUnit.KILOGRAMS]: 0,
              [ProductUnit.PIECE]: 0,
              [ProductUnit.GRAMS]: 0,
            })

          return <div>
            {map(ProductUnit, (productUnit) => {
              if (totals[productUnit as ProductUnit] > 0) {
                return <div
                  key={`harvestview-${productWithAmounts.id}-${productUnit}`}>{amountWithUnit(totals[productUnit as ProductUnit], productUnit as ProductUnit)}</div>;
              }
              return null;
            })}
          </div>

          //return amountWithUnit(sum(values(productWithAmounts.harvestAmounts)), productWithAmounts.unit);
        }}
      />
      <Column
        header="Pakketten"
        field="harvestAmounts.amountForBasketCompositions"
        body={amountCell('amountForBasketCompositions')}
      />
      <Column
        header="Extra's"
        field="harvestAmounts.amountForExtraProducts"
        body={amountCell('amountForExtraProducts')}
      />
      <Column
        header="Andere"
        field="harvestAmounts.amountForOtherClientOrders"
        body={amountCell('amountForOtherClientOrders')}
      />
      <Column
        header="Hoevewinkel"
        field="harvestAmounts.amountForShopOrders"
        body={amountCell('amountForShopOrders')}
      />
    </DataTable>
  </div>;
};

export default HarvestView;
