import {gql} from "@apollo/client";
import { ProductFields_basicFragment } from "shared/queries/product.gql";

export const SalesQuery = gql`
${ProductFields_basicFragment}
query SalesData($farmId: ID!) {
  sequences: sequences(where: {farm: {id: $farmId}}) {
    id
    sequenceType
    prefix
    nextValue
    season
  }
  clientOrders: clientOrders(where: {
    client: {
      type: BUSINESS,
      farm: {id: $farmId}
    }
  }) {
    id
    createdAt
    updatedAt
    orderDate
    deliveryDate
    deliveryEndDate
    delivery
    status
    orderDiscount
    client {
      id
      companyName
      name
      firstName
      street
      streetNumber
      cityPostalCode
      city
      type
      vatNumber
      email
      billingEmail
      group { id}
    }
    productOrders {
      id
      productDescription
      product {
        ...ProductFields_basic
        name
        extra
        amount
        unit
        vat
        type
        availableAsAlternativeUnitFor
        alternativeUnit
        avgWeight
      }
      priceExcl
      quantity
      remark
      ambassadorDiscount
      deliveries { plannedDeliveryDate }
    }

    invoice {
      id
      createdAt
      type
      number
      filename
      data
      payments {
        id
        paymentDate
        dueDate
        amount
        paid
      }
    }
  }
  clientGroups: clientGroups(where: {farm: {id: $farmId}}, orderBy: name_ASC) {
    id
    name
    profitMargin
    order
    code
    default
  }
  products: products(where: {farm: {id: $farmId}}) {
      id
      category {
        id
        name
      }
      productPrices(where: {OR: {endDate: null} }) {
        id
        clientGroup { id }
        value
        base
        endDate
        note
        unit
      }
      type
      name
      portionWillem
      portionDirk
      groupName
      extra
      unit
      amount
      avgWeight
      availableAsAlternativeUnitFor
alternativeUnit
      productAvailabilities(where: {endDate: null }) {
        id
        clientGroup { id code }
        startDate
        endDate
        note
      }
      status
      vat
      image
  }
}`;

export const CreateProductMutation = gql`
mutation CreateProduct(
  $data: ProductCreateInput!
) {
  createProduct(data: $data) {
    id
    type
    category {
      id
      name
    }
    productPrices {
      id
      value
      startDate
      endDate
      clientGroup {
        id
      }
      base
      note
    }
    name
    unit
    amount
    groupName
    extra
    portionWillem
    portionDirk
    avgWeight
    productAvailabilities(where: {endDate: null }) {
      id
      clientGroup { id code }
      startDate
      endDate
      note
    }
    status
    vat
    image
  }
}
`;

export const UpsertClientOrderMutation = gql`
mutation UpsertClientOrder($id: ID, $createData: ClientOrderCreateInput!, $updateData: ClientOrderUpdateInput!) {
 	upsertClientOrder(
    	where: {id: $id}
    	create:$createData
    	update: $updateData
  ) {
    id
    createdAt
    updatedAt
    orderDate
    deliveryDate
    deliveryEndDate
    delivery
    status
    orderDiscount
    client {
      id
      name
      firstName
      street
      streetNumber
      cityPostalCode
      city
      type
      vatNumber
      email
      billingEmail
      group { id}
    }
    productOrders {
      id
      product {
        id
        vat
      }
      priceExcl
      quantity
      remark
      ambassadorDiscount
      deliveries { plannedDeliveryDate }
    }
    invoice {
      id
      createdAt
      type
      number
      filename
      data
      payments {
        id
        paymentDate
        dueDate
        amount
        paid
      }
    }
  }
}
`;

export const MarkClientOrdersForInvoiceAsSentMutation = gql`
mutation MarkClientOrdersForInvoiceAsSent($id: ID) {
 	updateManyClientOrders(
    	where: {
      	invoice: {id: $id}
      }
    	data: {
        status: INVOICE_SENT
      }
  ) {
    count
  }
}`;

export const AddPaymentToInvoiceMutation = gql`
mutation AddPaymentToInvoice($id: ID!, $paymentDate: DateTime!, $amount: Float!) {
 	updateManyClientOrders(
    where: {
      invoice: {
        id: $id
      }
    }
    data: {
      status: PAID
    }
  ) {
    count
  }
  updateInvoice(
    	where: {id: $id}
    	data: {
        payments: {
          create: [{
            paymentDate: $paymentDate
            amount: $amount
            dueDate: $paymentDate
            paid: true
          }]
        }
      }
  ) {
    id
    createdAt
    type
    number
    filename
    data
    payments {
      id
      paymentDate
      dueDate
      amount
      paid
    }
  }
}`;

export const UpsertProductMutation = gql`
mutation UpsertProduct($id: ID, $createData: ProductCreateInput!, $updateData: ProductUpdateInput!) {
 	upsertProduct(
    	where: {id: $id}
    	create:$createData
    	update: $updateData
  ) {
    id
    type
    category {
      id
      name
    }
    name
    unit
    amount
    groupName
    extra
    portionWillem
    portionDirk
    avgWeight
    productAvailabilities(where: {endDate: null }) {
      id
      clientGroup { id code }
      startDate
      endDate
      note
    }
    status
    vat
    image
  }
}
`;

export const DeleteProductMutation = gql`
mutation DeleteProduct($id: ID) {
 	deleteProduct(
    	where: {id: $id}
  ) {
    id
  }
}
`;

export const UpdateProductMutation = gql`
mutation UpdateProduct($id: ID!, $data: ProductUpdateInput!) {
  updateProduct(
    where: {id: $id},
  	data: $data
  ) {
    id
    type
    category {
      id
      name
    }
    name
    unit
    amount
    groupName
    extra
    portionWillem
    portionDirk
    avgWeight
    productAvailabilities(where: {endDate: null }) {
      id
      clientGroup { id code }
      startDate
      endDate
      note
    }
    status
    vat
    image
    productPrices {
      id
      value
      startDate
      endDate
      note
      clientGroup {
        id
      }
      base
    }
  }
}
`;


export const GetClientOrdersByStatusQuery = gql`
${ProductFields_basicFragment}
query GetClientOrdersToProcess($status: [ClientOrderStatus!], $farmId: ID!) {
  clientOrders(where: {
    status_in: $status
    client: { farm: {id: $farmId}}
    createdAt_gt: "2022-12-31",
#    client: {
#      id: "clluyytktcir80775tx2fnzuu"
#    }
    OR: [
      {
        client: {
          group: {
            code_not: "standaard"
          }
        }
      },
      {
        client: {id: "clgzma4zs2nza07758y0zkm7z"}
      }
    ]
  }) {
    id
    createdAt
    updatedAt
    orderDate
    deliveryDate
    deliveryEndDate
    delivery
    status
    orderDiscount
    client {
      id
      companyName
      name
      firstName
      street
      streetNumber
      cityPostalCode
      city
      type
      vatNumber
      email
      billingEmail
      group { id}

    }
    productOrders {
      id
      productDescription
      product {
        ...ProductFields_basic
        name
        extra
        amount
        unit
        vat
        type
        availableAsAlternativeUnitFor
alternativeUnit
        avgWeight

      }
      priceExcl
      quantity
      remark
      ambassadorDiscount
      deliveries { plannedDeliveryDate }
    }
    invoice {
      id
      createdAt
      type
      number
      filename
      data
      payments {
        id
        paymentDate
        dueDate
        amount
        paid
      }
    }
  }
}`;
