import gql from "graphql-tag";
import {ClientFieldsFragment} from "./client.gql";
import { ProductFields_basicFragment } from "./product.gql";

export const InvoicesQuery = gql`
query Invoices($where: InvoiceWhereInput!) {
  invoices(where: $where) {
    id
    number
    filename
    type
    createdAt
    data
     clientOrders {
      client { id ibanNumbers }
    }
    payments {
    	id
      paymentDate
      amount
      dueDate
      paid
      createdAt
    }
  }
}`;

export const InvoiceQuery = gql`
${ProductFields_basicFragment}
${ClientFieldsFragment}
query Invoice($id: ID!) {
  client: clients(where: {
    clientOrders_some: {
      invoice: {id: $id}
    }
  }) {
    ...ClientFields
  }
  invoice(where: {id: $id}) {
    createdAt
    data
    filename
    id
    number
    type
    clientOrders {
      id
      deliveryEndDate
      deliveryDate
      orderDiscount
      delivery
      productOrders {
        id
        productDescription
        priceExcl
        product {
          ...ProductFields_basic
          name
          unit
          amount
          type
          avgWeight
          availableAsAlternativeUnitFor
          alternativeUnit
          vat
        }
        ambassadorDiscount
        quantity
        deliveries { id }
      }
    }
    payments {
      id
      paymentDate
      amount
      dueDate
      paid
      createdAt
    }
  }
}`;

export const InvoiceUpdateMutation = gql`
mutation InvoiceUpdate($id: ID!, $data: InvoiceUpdateInput!) {
  updateInvoice(
    where: {id: $id}
    data: $data
  ) {
    id
  }
}
`;


export const DeleteInvoiceMutation = gql`
  mutation DeleteInvoice($id: ID!) {
    updateManyClientOrders(
      where: {invoice: {id: $id}}
      data: {
        status: CREATED
      }
    ) {
      count
    }
    deleteManyPayments(
      where: {
        invoice: {
          id: $id
        }
      }

    ) {
      count
    }
    deleteInvoice(
      where: {
        id: $id
      }
    ) {
      id
      clientOrders {
        id
        status
      }
    }
  }
`;
