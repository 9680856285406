import {DeliveryLocation, Vat} from "../__generated__/types";
import {chain, compact, differenceBy} from "lodash";
import {momentFromIso8601} from "../shared/utils/date.utils";
import {BasketSize} from "../shared/utils/product.utils";
import {clientOrderTotals} from "./clientOrder.utils";
import {ProductOrderType} from "../shared/utils/productOrder.utils";

interface ProductOrderForBasketCompositionType {
  deliveries: DeliveryType[] | null;
}

export interface DeliveryType {
  plannedDeliveryDate: string;
  deliveryLocation?: DeliveryLocation | null | undefined;
  deliveryDate?: string | undefined | null;
  basketComposition?: { id: string } | null;
  productOrder?: { clientOrder: { client: { id: string } } } | null | undefined;
}

export const findProductOrderByCategory =
  <T extends ProductOrderType>(productOrders: T[] | null | undefined, categoryCode: string): T | undefined => {
    return compact(productOrders).find(po => po.product?.category?.code === categoryCode);
  };

export const filterProductOrdersByCategories =
  <T extends ProductOrderType>(productOrders: T[] | null | undefined, categoryCodes: string[]): T[] => {
    return compact(productOrders)
      .filter(po => po.product?.category?.code && categoryCodes.includes(po.product.category.code));
  };

export const findProductOrderByProductCode =
  <T extends ProductOrderType>(productOrders: T[] | null | undefined, productCode: string): T | undefined => {
    return compact(productOrders).find(po => po.product?.code === productCode);
  };

export const findProductOrderWitProductCodeHavingSize = (size: BasketSize) =>
  <T extends ProductOrderType>(productOrders: T[] | null | undefined): T | undefined => {
    return size && compact(productOrders).find(po => po.product?.code && po.product.code.indexOf(size) > -1);
  };

export const filterProductOrdersWithProductCodeHavingSize = (size: BasketSize) =>
  <T extends ProductOrderType>(productOrders: T[] | null | undefined): T[] => {
    return size ? compact(productOrders).filter(po => po.product?.code && po.product.code.indexOf(size) > -1) : [];
  };

export const productOrderHasProductCodeHavingSize = (size: BasketSize) =>
  <T extends ProductOrderType>(productOrder: T | null | undefined): boolean => {
    return !!size && !!productOrder?.product?.code && productOrder.product.code.indexOf(size) > -1;
  };

export const productOrdersTotalIncl = (
  clientOrders: {
    orderDiscount?: number | null | undefined,
    productOrders: {
      product: { vat?: Vat | null | undefined } | null,
      priceExcl: number
    }[] | null
  }[]) => {
  return chain(clientOrders)
    .map(clientOrder => clientOrderTotals(clientOrder).incl.total)
    .sum()
    .value();
};

export const filterProductOrdersMissingInBasketComposition = <T extends ProductOrderForBasketCompositionType>(
  productOrders: T[] | null | undefined,
  basketComposition: { id: string, deliveryWeek: string } | undefined | null
): T[] => {
  if (!basketComposition) {
    return [];
  }

  let productOrdersWithDeliveryInSameWeek = compact(productOrders)
    .filter(productOrder =>
      compact(productOrder.deliveries)
        .filter(delivery => momentFromIso8601(delivery.plannedDeliveryDate).isSame(momentFromIso8601(basketComposition.deliveryWeek), 'isoWeek'))
        .filter(delivery => !delivery.basketComposition?.id)
        .length > 0
    );

  let connectedDeliveries = chain(productOrders)
    .map(productOrder => {
      let delivery = compact(productOrder.deliveries)
        .find(delivery => {
          return delivery.basketComposition && delivery.basketComposition?.id === basketComposition.id;
        });
      if (delivery) {
        return ({
          ...productOrder,
          delivery
        });
      }
      return null;
    })
    .compact()
    .value();

  return differenceBy(productOrdersWithDeliveryInSameWeek, connectedDeliveries, 'id');
};

export const filterProductOrdersWithDifferentBasketComposition = <T extends ProductOrderForBasketCompositionType>(
  productOrders: T[] | null | undefined,
  basketComposition: { id: string, deliveryWeek: string } | undefined | null
): T[] => {
  if (!basketComposition) {
    return [];
  }

  return compact(productOrders)
    .filter(productOrder =>
      compact(productOrder.deliveries)
        .filter(delivery =>
          momentFromIso8601(delivery.plannedDeliveryDate).isSame(momentFromIso8601(basketComposition.deliveryWeek), 'isoWeek')
        )
        .filter(delivery => delivery.basketComposition?.id && delivery.basketComposition.id !== basketComposition.id)
        .length > 0
    );
};

export const filterDeliveriesByClient = <T extends DeliveryType>(deliveries: T[] | null | undefined, clientId: string): T[] => {
  return compact(deliveries).filter(delivery => delivery.productOrder?.clientOrder.client.id === clientId);
};

export const filterDeliveredDeliveries = <T extends DeliveryType>(deliveries: T[] | null | undefined): T[] => {
  return compact(deliveries).filter(delivery => delivery.deliveryDate !== null);
};

export const filterPlannedDeliveries = <T extends DeliveryType>(deliveries: T[] | null | undefined): T[] => {
  return compact(deliveries).filter(delivery => delivery.deliveryDate === null && delivery.plannedDeliveryDate);
};

export const filterDeliveriesByDeliveryLocation = <T extends DeliveryType>(deliveries: T[], deliveryLocation: DeliveryLocation | null | undefined): T[] =>
  deliveries.filter(delivery => delivery.deliveryLocation === deliveryLocation);

export const productOrderHasProductWithCodeSubstring = (productOrder: { product: { code?: string | null | undefined } | null }, codeSubstring: string) =>
  productOrder.product?.code && productOrder.product.code.indexOf(codeSubstring) > -1
;

export const productOrderHasProductWithCategory = (productOrder: {
                                                     product: {
                                                       code?: string | null | undefined
                                                       category?: {
                                                         code?: string | null | undefined
                                                       } | null
                                                     } | null
                                                   },
                                                   categoryCodes: string[]) =>
    productOrder.product?.category?.code && categoryCodes.includes(productOrder.product.category.code);

export const isBasketProductOrder = (productOrder: { product: { code?: string | null | undefined } | null }) =>
  productOrderHasProductWithCategory(productOrder, ['pakketten']);
  //productOrderHasProductWithCodeSubstring(productOrder, 'pakketten');

export const isFlexBasketProductOrder = (productOrder: { product: { code?: string | null | undefined, flex?: boolean | null } | null | undefined}) =>
  productOrder?.product?.flex;
  //productOrder.product?.flex || productOrderHasProductWithCodeSubstring(productOrder, '-flex-');

export const isFixedBasketProductOrder = (productOrder: { product: { code?: string | null | undefined, flex?: boolean | null } | null }) =>
  !productOrder?.product?.flex;
  // productOrderHasProductWithCodeSubstring(productOrder, '-vast-');

export const isSelfHarvestProductOrder = (productOrder: { product: { code?: string | null | undefined } | null }) =>
  productOrderHasProductWithCategory(productOrder,['zelfoogst']);
// productOrderHasProductWithCodeSubstring(productOrder, 'zelfoogst');

export const isBoxProductOrder = (productOrder: { product: { code?: string | null | undefined } | null }) =>
  productOrderHasProductWithCodeSubstring(productOrder, 'pakket-bak');

export const isExtraProductProductOrder = (productOrder: { product: { code?: string | null | undefined } | null }) =>
  productOrderHasProductWithCategory(productOrder, ['eieren', 'aardappelen', 'uien']);

export const productOrderIsTrial = (productOrder: { product: { code?: string | null } | null }) => {
  return productOrder.product?.code && productOrder.product.code.indexOf('trial') > -1;
};
