import {useQuery} from '@apollo/client';
import React from 'react';
import {ClientOrderQuery} from 'shared/queries/clientOrder.gql';
import {BasketCompositions, BasketCompositionsVariables, BasketCompositionType, ClientOrder} from '__generated__/types';
import {
  filterProductOrdersByCategories, isFlexBasketProductOrder,
} from "../../../../utils/productOrder.utils";
import {compact, first} from "lodash";
import {iso8601_DayFullDdMmm} from "../../../../shared/utils/date.utils";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import {toCity} from 'shared/utils/deliveryLocation.utils';
import {productCategoryIcon} from "../../../../shared/utils/productCategory.utils";
import {TODAY} from "../../../../shared/config";
import FlexNotifications from 'pages/ClientBasketDeliveriesPage/ClientBasketFlexPage/components/FlexNotifications';
import {basketProductOrderHasInsufficientPoints_error} from "../../../../utils/clientOrder.utils";
import BasketContents from "./BasketContents";
import {findBasketProductOrder} from "../../../../shared/utils/productOrder.utils";
import {BasketCompositionsQuery} from "../../../../shared/queries/basketComposition.gql";
import {totalPortions} from "../../../../shared/utils/basketComposition.utils";
import {getBasketSize} from "../../../../shared/utils/product.utils";
import {nextDelivery} from "../../../../shared/utils/clientOrder.utils";
import {useUserObject} from "../../../../shared/context/UserContext";

interface DashboardItemClientNextBasketProps {
  clientOrderId: string;
}

const DashboardItemClientNextBasket = (props: DashboardItemClientNextBasketProps) => {
  const navigate = useNavigate();
  // const {activeFarm} = useUserObject();

  const {data} = useQuery<ClientOrder>(ClientOrderQuery, {variables: {id: props.clientOrderId}});
  // const variables: BasketCompositionsVariables = {
  //   farmId: activeFarm?.id||'',
  //   where: {
  //     type: BasketCompositionType.FIXED,
  //     deliveryWeek_gte: TODAY.clone().startOf('isoWeek'),
  //     deliveryWeek_lte: TODAY.clone().endOf('isoWeek'),
  //   }
  // };
  // const {data: fixedBasketCompositionData} = useQuery<BasketCompositions>(BasketCompositionsQuery, {variables});

  let basketProductOrder = findBasketProductOrder(data?.clientOrder?.productOrders);
  let extraProductOrders = filterProductOrdersByCategories(data?.clientOrder?.productOrders, ['uien', 'eieren', 'aardappelen']);

  let deliveries = compact(basketProductOrder?.deliveries).filter(delivery => !delivery.cancelled);
  const firstUndelivered = nextDelivery(deliveries);
  let extraProductDeliveries = extraProductOrders
    .filter(productOrder => compact(productOrder.deliveries).findIndex(delivery => delivery.plannedDeliveryDate === firstUndelivered?.plannedDeliveryDate) > -1
    );

  let isFlex = basketProductOrder && isFlexBasketProductOrder(basketProductOrder);

  // let fixedBasketComposition = first(fixedBasketCompositionData?.basketCompositions);

  // let additionalPortions = 0;
  // if (basketProductOrder
  //   && firstUndelivered
  //   && firstUndelivered.basketComposition === null
  //   && fixedBasketComposition
  // ) {
  //   additionalPortions = totalPortions(fixedBasketComposition?.items, getBasketSize(basketProductOrder.product), firstUndelivered.deliveryLocation);
  // }

  let hasInsufficientPointsError = basketProductOrder && basketProductOrderHasInsufficientPoints_error(basketProductOrder);

  let totalDeliveries = deliveries;
  let nrOfCurrentDelivery = totalDeliveries.findIndex(d => d.id === firstUndelivered?.id) + 1;

  return <div>
    <div className="border-1 border-300 p-2 md:p-3 shadow-1">
      <div className="flex justify-content-between">
        <div className="text-xl md:text-2xl lg:text-3xl font-normal">{basketProductOrder?.product?.name}</div>
        <div className="text-sm md:text-base lg:text-lg xl:text-xl font-normal text-600 flex align-items-center">
          <div>Pakket {nrOfCurrentDelivery} van {totalDeliveries.length}</div>
        </div>
      </div>

      <div className="text-base flex flex-column md:flex-row md:align-items-center text-700 justify-content-between">
        <div className="flex flex-row md:flex-column justify-content-between">
          <div className="flex align-items-center">
            <i className="mr-1 pi pi-calendar"/>
            <span
              className="mr-3">{firstUndelivered ? iso8601_DayFullDdMmm(firstUndelivered.plannedDeliveryDate) : ''}</span>
          </div>
          <div className="flex align-items-center">
            <i className="mr-1 pi pi-map-marker text-700"/>
            {firstUndelivered?.deliveryLocation ? toCity(firstUndelivered?.deliveryLocation) : ''}
          </div>
        </div>
        {isFlex && basketProductOrder && firstUndelivered?.basketComposition &&
          <div className="">
            <Button
              label={'Verplaats dit pakket naar een latere datum'}
              size="small"
              className='p-button-link p-0 font-light text-xs white-space-nowrap'
              icon={'pi pi-calendar'}
              iconPos={'left'}
              onClick={() => navigate(`/mijnpakket/leveringen?clientOrderId=${props.clientOrderId}&deliveryId=${firstUndelivered?.id}`)}
            />
          </div>
        }
      </div>

      {basketProductOrder && data?.clientOrder && <FlexNotifications
        basketProductOrder={basketProductOrder}
        clientEmail={data.clientOrder.client?.email}
        clientOrderId={data.clientOrder.id}
        nextDelivery={firstUndelivered}
        // additionalPortions={additionalPortions}
      />}

      {!hasInsufficientPointsError &&
        <>
          <div className="grid mt-4">
            <BasketContents
              basketProductOrder={basketProductOrder}
              clientOrderId={props.clientOrderId}
              delivery={firstUndelivered}
            />

            <div className="col-12 md:col-6 mb-3">
              <div
                className="text-base md:text-lg lg:text-xl font-medium pb-1 border-bottom-1 border-green-alpha-40">Mijn
                extra's
              </div>
              <div className="py-2">
                {extraProductDeliveries.map(productOrder =>
                  <div key={productOrder.id} className={"flex align-items-center my-2 pb-2"}>
                    <img
                      className="w-2rem h-2rem"
                      alt={productOrder.product?.name}
                      src={`../extras/${productCategoryIcon(productOrder.product?.category)}`}
                    />
                    <div className="ml-2 text-lg font-medium">{productOrder.product?.name}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {isFlex &&
            <div className='flex flex-column justify-content-between align-items-center'>
              <Button
                label={'Verplaats dit pakket naar een latere datum'}
                size="small"
                className='p-button-link p-0 font-light text-xs'
                icon={'pi pi-calendar-times'}
                iconPos={'left'}
                onClick={() => navigate(`/mijnpakket/leveringen?clientOrderId=${props.clientOrderId}&deliveryId=${firstUndelivered?.id}`)}
              >
              </Button>
            </div>
          }
        </>
      }
    </div>
  </div>;
};

export default DashboardItemClientNextBasket;
